import React from "react"
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import Popper from "popper.js"

import App from "./App"
import * as serviceWorker from "./serviceWorker"

import {store} from './store';

import "./polyfills"
import './i18n';

Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

const app = (
  <Provider store={store}>
    <App />
  </Provider>
)

render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

const config = { pushKey: "BDcjuZgVkXJgE7KG0laGbRsR-UTclrMaGZCiPG_wSn1Fm0K52nqBvM_7YvhN3__b16GYc_iwULRmHOye8L4d1Jw"}
serviceWorker.register(config)
// serviceWorker.unregister()
