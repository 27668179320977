/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:2cf093a6-9bda-410e-9fef-a1301137e3b9",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_RuJwLcPuN",
    "aws_user_pools_web_client_id": "jbgocki31k6vvroa5pke0e2sk",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "econq59b5c127e4bb43b6bf9e96b2f86c3fb880803-customthr",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://xpy547zv5vcalna4akf3fcoaiq.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "restapi",
            "endpoint": "https://hl97i1y5h3.execute-api.eu-west-2.amazonaws.com/customthr",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
